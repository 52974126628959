import { makeAutoObservable } from 'mobx'

import type { Model } from '@src/service/model'

export interface CodableTag {
  id: string
  name: string
  emoji: string | null
  color: string | null
  source: string
}

export default class TagModel implements Model, CodableTag {
  private raw: CodableTag

  constructor(tag: CodableTag) {
    this.raw = tag
    makeAutoObservable(this)
  }

  get id(): string {
    return this.raw.id
  }

  get name(): string {
    return this.raw.name
  }

  get emoji(): string | null {
    return this.raw.emoji
  }

  get color(): string | null {
    return this.raw.color
  }

  get source(): string {
    return this.raw.source
  }

  deserialize(json: CodableTag) {
    this.raw = json
    return this
  }

  serialize(): CodableTag {
    return this.raw
  }
}
